<template>
  <div class="w-full relative">
    <v-lazy-image
      :src="img"
      :srcset="imgSet"
      sizes="(max-width: 400px) 960px"
      src-placeholder="/images/about_header_copy.jpg"
      alt="PLC Layout"
    />
  </div>
</template>

<script>
export default {
  name: "ImageContainer",
  props: ["img"],
};
</script>

<style scoped>
.v-lazy-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
